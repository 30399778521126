<template>
    <section>
        <!-- <div class="" style="display: none;"> -->
            <wrapper-component :id="'price'">
            <TitleComponent :title="title" />
            <div class="price__content">
                <PriceItem v-for="(item, index) in list[izd]" :key="index" :izd="izd" :header="item.header" :img="item.img" :price="item.price" :oldprice="item.oldprice" :size="item.size" :material="item.material"/>
            </div>
        </wrapper-component>
        <!-- </div> -->
       

    </section>
</template>

<script>
import './price.css';
import WrapperComponent from "@/components/Wrappers/WrapperComponent.vue";
import axios from 'axios';
import PriceItem from "./PriceItem/PriceItem";
import TitleComponent from '@/components/UI/Title/TitleComponent.vue';
    export default{
        name: 'AdvantagesComponent',
        props: ['sectionName', 'title', 'izd'],
        components: {WrapperComponent, PriceItem, TitleComponent},
        data(){
            return{
            usingList: null,
            list: {
            },
            list_reserve: {
                rolo: [
                        {header: 'INTEGRA SLIM', img: require(`@/assets/images/price/rolo/integra-slim.jpg`), price: '1995', size: '61х158', material: 'Эко белый 01'},
                        {header: 'Integra Box+', img: require(`@/assets/images/price/rolo/integra-box-plus.jpg`), price: '4688', size: '60x130', material: 'Натали ВО, розовый 33'},
                        {header: 'Integra slim duo', img: require(`@/assets/images/price/rolo/integra-slim-duo.jpg`), price: '5723', size: '69x150', material: 'Basic св.коричневый'},
                        {header: 'roll', img: require(`@/assets/images/price/rolo/roll.jpg`), price: '3297', size: '100x170', material: 'Прима 29, бежевый'},
                        {header: 'clic box duo', img: require(`@/assets/images/price/rolo/clic-box-duo.jpg`), price: '21889', size: '160x160', material: 'Barcelona бежевый'},
                        {header: 'grande', img: require(`@/assets/images/price/rolo/grande.jpg`), price: '19153', size: '270x170', material: 'Орбита ВО 29'},
                    ],
                roma: [
                        {header: 'ROMA', img: require(`@/assets/images/price/roma/roma_sanremo.jpg`), price: '9460', size: '65x130', material: 'Санремо ультра 29, бежевый'},
                        {header: 'ROMA', img:  require(`@/assets/images/price/roma/roma_shifon.jpg`), price: '10746', size: '65x130', material: 'Шифон 01, белый'},
                        {header: 'INTEGRA ROMA', img: require(`@/assets/images/price/roma/roma_turin.jpg`), price: '5730', size: '55x120', material: 'Турин 01, белый'},
                    ],
                    gform: [
                        {header: 'G-FORM', img: require(`@/assets/images/price/gform/1003.jpg`), price: '5465', size: '200х170', material: '1003, белая, (18мкм)'},
                        {header: 'G-FORM', img: require(`@/assets/images/price/gform/165.jpg`), price: '2183', size: '70х150', material: ' 165'},
                        {header: 'INTEGRA G-FORM', img: require(`@/assets/images/price/gform/6040010.jpg`), price: '3378', size: '65х120', material: '6040010, дерево'},
                    ],
                    plisse: [
                        {header: 'INTEGRA PLISSE', img: require(`@/assets/images/price/plisse/eco-white.jpg`), price: '4912', size: '60х125', material: 'Эко, белый'},
                        {header: 'INTEGRA PLISSE', img: require(`@/assets/images/price/plisse/perlamutr-white.jpg`), price: '8262', size: '65х125', material: 'Креп перламутр, белый'},
                        {header: 'INTEGRA PLISSE', img: require(`@/assets/images/price/plisse/light-cream.jpg`), price: '7715', size: '55х120', material: 'Лен, светло-кремовый'},
                    ],
                    vform: [
                        {header: 'V-form', img: require(`@/assets/images/price/vform/vform_line1.jpg`), price: '3795', size: '180х180', material: 'Лайн 01, белый'},
                        {header: 'V-form', img: require(`@/assets/images/price/vform/vform_line32.jpg`), price: '7377', size: '300х240', material: 'Лайн 32, т.бежевый New'},
                        {header: 'V-form', img: require(`@/assets/images/price/vform/vform_mystery.jpg`), price: '8405', size: '250х200', material: 'Мистерия 08, серый'},
                    ],
                
            },
              
              
            
            xml: null,
                 
            }
        },
        async mounted (){
            const list = {};
            const result = await axios.get('https://api.foroom.ru/uploads/open/feeds/goods.xml',{});
            const xmlStr = result.data;
            const parser = new DOMParser();
            const doc = parser.parseFromString(xmlStr, "application/xml");
            
            doc.querySelectorAll('offer').forEach((el)=>{
                if(el.querySelector('oldprice')){
                    let izdName = el.id.split('_')[0];
                    if(izdName === 'zebra'){
                        izdName = 'rolo'
                    }
                    if(izdName === 'vblinds'){
                        izdName = 'vform'
                    }
                    if(izdName === 'hblinds'){
                        izdName = 'gform'
                    }
                    if(izdName === 'introma'){
                        izdName = 'roma'
                    }
                  
                    if(!list[izdName]){
                        list[izdName] = [];
                    }
               
                    const newItem = {
                        header: el.querySelector('name').textContent.replace(el.querySelector('model').textContent, ''), 
                        img: el.querySelector('picture').textContent, 
                        price: el.querySelector('price').textContent, 
                        oldprice: el.querySelector('oldprice') ?  el.querySelector('oldprice').textContent : null, 
                        size: '', 
                        material: el.querySelector('model').textContent
                    }

                    if(list[izdName].length < 6){
                        list[izdName].push(newItem);
                    }

                    console.log(list);
                    

                 
                }
               

                
            })

            doc.querySelectorAll('offer').forEach((el)=>{
                    let izdName = el.id.split('_')[0];
                    if(izdName === 'zebra'){
                        izdName = 'rolo'
                    }
                    if(izdName === 'vblinds'){
                        izdName = 'vform'
                    }
                    if(izdName === 'hblinds'){
                        izdName = 'gform'
                    }
                  
                    if(!list[izdName]){
                        list[izdName] = [];
                    }
               
                    const newItem = {
                        header: el.querySelector('name').textContent.replace(el.querySelector('model').textContent, ''), 
                        img: el.querySelector('picture').textContent, 
                        price: el.querySelector('price').textContent, 
                        oldprice: el.querySelector('oldprice') ?  el.querySelector('oldprice').textContent : null, 
                        size: '', 
                        material: el.querySelector('model').textContent
                    }

                    if(list[izdName].length < 6){
                        list[izdName].push(newItem);
                    } 
            })
            // if(!list[this.izd]){
            //     list[this.izd] = this.list_reserve[this.izd]
            // }
            this.list = list;   
            
        },
        // watch:{
        //     izd(){
        //         if(!this.list[this.izd]){
        //         this.list[this.izd] = this.list_reserve[this.izd]
        //     }
      
        //     },
        // },
    }
</script>

<style>
</style>