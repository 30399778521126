<template>
  <wrapper-component :id="'aboutUs'">
    <TitleComponent :title="title" />
    <p class="standart-text" v-for="(item, index) of textList" :key="index">{{ item }}</p>
    <div class="our-command">
      <h2 class="our-command__title">Наша команда</h2>
      <ul class="our-command__list">
        <li class="our-command__item" v-for="(item, index) of commandList" :key="index">
          <img
            :src="require(`@/assets/images/command/${item.img}`)"
            class="our-command__img"
            :alt="'command_' + (index + 1)"
          />
          <span class="our-command__name">{{ item.name }}</span>
          <span class="our-command__subtitle bold standart-text">{{ item.subtitle }}</span>
          <span class="our-command__description standart-text">{{item.description}}</span>
        </li>
      </ul>
    </div>
  </wrapper-component>
</template>

<script>
import TitleComponent from "@/components/UI/Title/TitleComponent.vue";
import "./about-us.css";
import WrapperComponent from "@/components/Wrappers/WrapperComponent.vue";
export default {
  name: "AboutUsComponent",
  components: { WrapperComponent, TitleComponent },
  props: ["title"],
  data() {
    return {
      textList: [
        `Студия «ЖАЛЮЗИ-time» работает в Рязани более 15 лет.`,
        `Многие рязанцы и предприятия города смогли по достоинству оценить профессиональную работу наших текстильных дизайнеров.`,
        `Начиная с множества эскизов, создаются уютные и комфортные пространства в квартире, загородном доме или офисе. Оригинальные текстильные решения основаны на профессиональном образовании и многолетнем опыте создания комфорта.`,
        `Студия «ЖАЛЮЗИ-time» с 2015 года официально представляет солнцезащитные изделия торговой марки FOROOM.`,
      ],
      commandList: [
        {
          img: "larisa.jpg",
          description: `Знает все о солнцезащитных системах почти все. Поможет дополнить ваше окно до максимальной комплектации - Вашего комфорта и уюта в доме!`,
          name: "Лариса Валериевна",
          subtitle: 'специалист по розничным продажам, текстильный дизайнер  +7 (953) 731-37-53'
        },
        {
          img: "andrew.jpg",
          description: "Знает все о солнцезащитных системах почти все. Поможет дополнить ваше окно до максимальной комплектации - Вашего комфорта и уюта в доме!",
          name: "Андрей Владимирович",
          subtitle: 'специалист дилерской сети +7 (4912) 993-559'
        },
      ],
    };
  },
};
</script>
