<template>
  <header class="header">
    <wrapper-component :classes="''">
      <div style="width: 100%" v-if="!fullDesktop"><burger-component :list="dropdownList" /></div>
      <div class="header__container">
        <a class="header__logo-link" href="https://foroom.ru/">
        <img
          class="header__logo header__logo_foroom"
          :width="fullDesktop ? 190 : 215"
          src="@/assets/images/foroom_logo.png"
          alt=""
        />
      </a>
        <span class="header__mark">Официальный представитель Foroom</span>
        <div class="user-logo">
          <img
          class="header__logo"
          :width="fullDesktop ? 220 : 240"
          src="@/assets/images/zhaluzi-time.png"
          alt=""
        />
        </div>
       
        <div class="header__foroom-block">
          <span>Ваши окна в полной комплектации</span>
        </div>
        <div class="header__contacts">
          <div class="header__numbers">
          <a
            v-for="item of phoneNumbers"
            :key="item"
            class="header__number"
            :href="'tel:' + item"
            >{{ item }} <span v-if="phoneNumbers.indexOf(item) < phoneNumbers.length - 1">,</span></a
          >
          
        </div>
        <a v-if="fullDesktop" class="header__number" href="#">г.Рязань, БЦ "НИТИ" ул. Яблочкова, дом 6, вход №5</a>
        </div>
     

        <!-- <span v-if="!fullDesktop" class="header__mark header__mark_plus"
          >Изготовим точно по вашим размерам. Закажите сейчас и
          получите</span
        >
        <span v-if="!fullDesktop" class="header__mark header__mark_plus header__mark_red"
          >Дополнительную скидку</span
        > -->
      </div>
      <div v-if="fullDesktop">
        <navigation-component :list="navList" :dropdownList="dropdownList" />
      </div>
    </wrapper-component>
  </header>
  <section class="underheader">
    <wrapper-component :classes="'underheader__container'">
      <span class="underheader__mark"
        >
        ИЗГОТОВИМ ТОЧНО ПО ВАШИМ РАЗМЕРАМ. 
        </span
      >
      
      <span class="underheader__mark"
        ><span class="underheader__mark underheader__mark_red">ПОКУПАЙТЕ ВЫГОДНО С ПРЕДЛОЖЕНИЕМ МЕСЯЦА ОТ FOROOM! </span></span
      >
    </wrapper-component>
  </section>
</template>

<script>
import "./header.css";
import WrapperComponent from "../Wrappers/WrapperComponent.vue";
// import device from "current-device";
import BurgerComponent from "./Burger/BurgerComponent.vue";
import NavigationComponent from "./Navigation/NavigationComponent.vue";


export default {
  components: { WrapperComponent, BurgerComponent, NavigationComponent },
  name: "HeaderComponent",
  data() {
    // const mediaQuery = window.matchMedia("(min-width: 1025px)");
    return {
      // mediaQuery,
      // device: device,
      // fullDesktop: mediaQuery.matches,
      phoneNumbers: [
       "+7 (953) 731-37-53", '+7 (4912) 993-559'],
      navList: [
        { text: "Продукция", method: "click", anchor: null, classes: "dropdown-start" },
        { text: "Как мы работаем", method: "scroll", anchor: "howWeWork", classes: "" },
        {
          text: "Преимущества работы с нами",
          method: "scroll",
          anchor: "advantages",
          classes: "",
        },
        { text: "Стоимость", method: "scroll", anchor: "price", classes: "" },
        { text: "Об организации", method: "scroll", anchor: "aboutUs", classes: "" },
        { text: "Ответы на вопросы", method: "scroll", anchor: "faq", classes: "" },
      ],
      dropdownList: [{link: '/rolo', text: 'Рулонные шторы'},{link: '/roma', text: 'Римские шторы'}, {link: '/plisse', text: 'Шторы плиссе'}, {link: '/gform', text: 'Горизонтальные жалюзи'}, {link: '/vform', text: 'Вертикальные жалюзи'}],
           
    };
  },
  computed:{
    fullDesktop(){
      return this.$store.getters.fullDesktop;
    }
  }
  // methods: {
  //   changeWidth() {
  //     this.fullDesktop = this.mediaQuery.matches;
  //   },
  // },
  // mounted() {
  //   this.mediaQuery.addEventListener("change", this.changeWidth);
  // },
  // beforeUnmount() {
  //   this.mediaQuery.removeEventListener("change", this.changeWidth);
  // },
};
</script>
