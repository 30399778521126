<template>
    <section>
        <wrapper-component :id="'howWeWork'">
            <TitleComponent :title="title" :customClass="'working__title'" />
            <ListItem v-for="(item, index) in list" :key="index" :color="item.color" :listNumber="index + 1" :text="item.text"/>
        </wrapper-component>
    </section>
</template>

<script>
import WrapperComponent from "@/components/Wrappers/WrapperComponent.vue";
import ListItem from "./ListItem/ListItem";
import TitleComponent from "@/components/UI/Title/TitleComponent.vue";
    export default{
        name: 'HowWeWork',
        props: ['sectionName', 'title'],
        components: {WrapperComponent, ListItem, TitleComponent},
        data(){
            return{
                    list: [
                        {text:`ВИЗИТ В САЛОН.
                            Оставьте заявку на сайте или позвоните нам. Мы оперативно свяжемся с Вами, чтобы согласовать удобное время для Вашего визита к нам в шоурум. У Вас будет возможность познакомиться с типами солнцезащитных изделий в натуральном виде, оценить качество и функциональность материалов, а также примерную стоимость заказа. Оформим договор.
                            При необходимости квалифицированного замера, согласуем удобное для Вас время.
                            .`, color: '#6b2b52'}, 
                        {text:`ЗАМЕР (при необходимости)
                            Эксперт приедет к Вам, окажет профессиональную консультацию, подберет изделие. Произведет замер. Рассчитает точную стоимость изделия.
                            `, color: '#5a7410'},
                        {text:`ИЗГОТОВЛЕНИЕ
                            Ваш заказ оперативно отправится на производство, где опытные мастера создадут изделие по вашим параметрам.
                            `, color: '#18637b'},
                        {text:`ИНФОРМИРОВАНИЕ О ГОТОВНОСТИ
                            По завершении производства изделия, мы оперативно свяжемся с Вами, чтобы согласовать удобную дату и время доставки и при необходимости монтаж.
                            `, color: '#c31b1b'},
                        {text:`ДОСТАВКА И МОНТАЖ
                            В согласованный день к Вам привезут заказ. При необходимости произведут монтаж.
                            `, color: '#9dc418'}
                    ]
            }
        }
    }
</script>

<style>
</style>