<template>
    <section :class="'not-found'">
      <wrapper-component>

        <img src="@/assets/images/404.svg" alt="Страница не найдена">
      </wrapper-component>
    </section>
  </template>
  <script>
  // Import Swiper Vue.js components

  import WrapperComponent from "@/components/Wrappers/WrapperComponent.vue";
  import './not-found.css';
  export default {
    name: "NotFound",
    components: {
      WrapperComponent,
    },
  
    computed:{
      fullDesktop(){
        return this.$store.getters.fullDesktop;
      },
    }
  };
  </script>
  